<template>
  <div class="shadow-xl rounded-xl bg-white mb-4 p-4 min-h-60vh flex flex-col">
    <h4 v-html="question.question"></h4>

    <div class="space-y-3 mt-4 flex-1">
      <template v-if="question.answers_type === 'multi-select'">
        <div
          v-for="answer in question.answers"
          :key="answer"
          class="bg-gray-200 text-center py-3 rounded-lg text-xs transition-all"
          :class="{
            'bg-brand-primary shadow-primary-blue font-bold':
              selectedAnswer === answer,
          }"
          @click="setSelectAnswer(answer)"
        >
          <p>{{ answer }}</p>
        </div>
      </template>

      <template v-else-if="question.answers_type === 'checkbox_other'">
        <t-checkbox-group
          v-model="selectedAnswer"
          name="answer[]"
          :options="question.answers"
        />
        <label class="flex items-center">
          <t-checkbox v-model="hasExtraAnswer" />
          <span class="ml-2 text-sm">{{
            $t("pages.assessment_quiz.other")
          }}</span>
        </label>

        <t-input
          v-show="hasExtraAnswer"
          :placeholder="$t('pages.assessment_quiz.which_drugs')"
          type="text"
          :disabled="!hasExtraAnswer"
          v-model.trim="extraAnswer"
        />
      </template>

      <template v-else-if="question.answers_type === 'radio'">
        <t-radio-group
          v-model="selectedAnswer"
          name="answer"
          :options="question.answers"
        ></t-radio-group>
      </template>

      <template v-else-if="question.answers_type === 'numeric'">
        <t-input
          type="number"
          placeholder="2"
          min="0"
          v-model="selectedAnswer"
        />
      </template>
    </div>

    <div
      class="flex mt-8"
      :class="{
        'flex-col space-y-2': activeIndex === questionsLength - 1,
        'space-x-2': activeIndex !== questionsLength - 1,
      }"
    >
      <t-button v-if="activeIndex > 0" @click="$emit('onPreviousClick')">{{
        $t("button_prev")
      }}</t-button>
      <t-button
        v-if="activeIndex !== questionsLength - 1"
        @click="onNextClick"
        :disabled="disabled"
        >{{ $t("button_next") }}</t-button
      >
      <t-button
        v-else
        variant="primary"
        :disabled="disabled"
        @click="onNextClick"
        >{{ $t("pages.assessment_quiz.submit") }}</t-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Item.vue",
  props: {
    question: {
      type: Object,
    },
    activeIndex: {
      type: Number,
    },
  },

  watch: {
    activeIndex(newValue) {
      if (this.results[`q-${newValue + 1}`]) {
        this.selectedAnswer = this.results[`q-${newValue + 1}`].answer;
        this.extraAnswer = this.results[`q-${newValue + 1}`].extraAnswer;
      }
    },
  },

  data() {
    return {
      selectedAnswer: undefined,
      hasExtraAnswer: false,
      extraAnswer: undefined,
    };
  },

  methods: {
    setSelectAnswer(answer) {
      this.selectedAnswer = answer;
    },

    onNextClick() {
      const result = {
        [`q-${this.activeIndex + 1}`]: {
          question: this.question.question,
          answer: this.selectedAnswer,
          extraAnswer: this.hasExtraAnswer ? this.extraAnswer : undefined,
        },
      };
      this.$store.commit("SET_ASSESSMENT_RESULTS", result);
      if (this.activeIndex !== this.questionsLength - 1) {
        this.$emit("onNextClick");
      } else {
        this.$store.dispatch("submitAssessmentQuiz");
        this.$gtm.trackEvent({
          category: "Risk assessment",
          action: "click",
          label: "User filled in risk assessment",
          value: `${this.user.uuid} has submitted an answer for his risk assessment`,
        });
      }
      this.selectedAnswer = undefined;
      this.extraAnswer = undefined;
    },
  },

  computed: {
    ...mapGetters({
      questionsLength: "questionsLength",
      results: "results",
      user: "getProfile",
    }),

    disabled() {
      if (
        this.question.answers_type === "checkbox_other" &&
        this.hasExtraAnswer
      ) {
        return !this.extraAnswer;
      }
      return !this.selectedAnswer;
    },
  },
};
</script>

<style scoped></style>
