<template>
  <div class="mx-8">
    <div v-if="questions.length">
      <h2 class="text-2xl font-bold mb-4">
        {{ $t("pages.assessment_quiz.title") }}
      </h2>

      <div
        class="flex flex-col items-center justify-center space-y-4 text-center layout-inner"
        v-if="loading"
      >
        <icon-loading class="w-4 h-4 text-brand-blue animate-spin" />
        <p class="text-xs font-semibold">{{ $t("action.loading") }}</p>
      </div>

      <template v-else v-cloak>
        <assessment-item
          v-if="!completed_quiz"
          :question="questions[activeIndex]"
          :active-index="activeIndex"
          @onPreviousClick="activeIndex--"
          @onNextClick="activeIndex++"
        />

        <div class="bg-white rounded-lg p-4" v-else>
          <h4 class="font-bold text-lg mb-4">
            Thank you!
          </h4>
          <div class="space-y-2">
            <p>{{ $t("risk_assessment.paragraph_2") }}</p>
          </div>

          <t-button class="mt-8" :to="{ name: 'home' }">{{
            $t("back_home")
          }}</t-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AssessmentItem from "components/Assessment/Item.vue";

export default {
  name: "Index",

  data() {
    return {
      activeIndex: 0,
      loading: true,
    };
  },

  components: {
    AssessmentItem,
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      await this.$store.dispatch("getAllQuestions");
      await this.$store.dispatch("canParticipateInAssessmentQuiz");
      this.loading = false;
    },
  },

  computed: {
    ...mapGetters({
      questions: "questions",
      completed_quiz: "completed_quiz",
    }),
  },
};
</script>

<style scoped></style>
